import * as React from "react"
import Markdown from "react-markdown"
import { useSelector, useDispatch } from "react-redux"

import * as styles from "../assets/css/about.module.scss"
import Layout from "../components/layout"
import Seo from "../components/seo"
import GlassParagraph from "../components/glassParagraph/glassParagraph"
import Ideas from "../components/ideas/ideas"
import { Strings } from "../constants/strings"
import { getLanguage } from "../redux/slices/languageSlice"
import { fetchPosts, selectInstaPosts } from "../redux/slices/instaSlice"
import {
  fetchAboutEntities,
  selectAboutPage,
} from "../redux/slices/aboutPageSlice"

const About = () => {
  const language = useSelector(getLanguage)
  const dispatch = useDispatch()
  const aboutPageEntities = useSelector(selectAboutPage)
  const instagram = useSelector(selectInstaPosts)

  const loadData = React.useCallback(async () => {
    try {
      if (instagram.posts.length === 0) {
        dispatch(fetchPosts())
      }
      if (!aboutPageEntities.entities) {
        dispatch(fetchAboutEntities(language))
      }
    } catch (e) {
      console.log(e)
    }
  }, [language, dispatch, instagram.posts, aboutPageEntities.entities])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <Layout>
      <Seo title="About us - Caspian Soft" />
      <h2>{aboutPageEntities.entities?.about_us_title}</h2>
      <div className={styles.glass_container}>
        <GlassParagraph readMore={false} butaImage={false}>
          <Markdown>{aboutPageEntities?.entities?.paragraph}</Markdown>
        </GlassParagraph>
      </div>
      <br />
      <Ideas form={true} title={Strings.haveAProjectInMind[language]} />
    </Layout>
  )
}

export default About
